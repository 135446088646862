/**
 * @type {Record<string, string>}
 */
export const likely = {
  aa: 'aa-latn-et',
  aai: 'aai-latn-zz',
  aak: 'aak-latn-zz',
  aau: 'aau-latn-zz',
  ab: 'ab-cyrl-ge',
  abi: 'abi-latn-zz',
  abq: 'abq-cyrl-zz',
  abr: 'abr-latn-gh',
  abt: 'abt-latn-zz',
  aby: 'aby-latn-zz',
  acd: 'acd-latn-zz',
  ace: 'ace-latn-id',
  ach: 'ach-latn-ug',
  ada: 'ada-latn-gh',
  ade: 'ade-latn-zz',
  adj: 'adj-latn-zz',
  adp: 'adp-tibt-bt',
  ady: 'ady-cyrl-ru',
  adz: 'adz-latn-zz',
  ae: 'ae-avst-ir',
  aeb: 'aeb-arab-tn',
  aey: 'aey-latn-zz',
  af: 'af-latn-za',
  agc: 'agc-latn-zz',
  agd: 'agd-latn-zz',
  agg: 'agg-latn-zz',
  agm: 'agm-latn-zz',
  ago: 'ago-latn-zz',
  agq: 'agq-latn-cm',
  aha: 'aha-latn-zz',
  ahl: 'ahl-latn-zz',
  aho: 'aho-ahom-in',
  ajg: 'ajg-latn-zz',
  ajt: 'ajt-arab-tn',
  ak: 'ak-latn-gh',
  akk: 'akk-xsux-iq',
  ala: 'ala-latn-zz',
  ali: 'ali-latn-zz',
  aln: 'aln-latn-xk',
  alt: 'alt-cyrl-ru',
  am: 'am-ethi-et',
  amm: 'amm-latn-zz',
  amn: 'amn-latn-zz',
  amo: 'amo-latn-ng',
  amp: 'amp-latn-zz',
  an: 'an-latn-es',
  anc: 'anc-latn-zz',
  ank: 'ank-latn-zz',
  ann: 'ann-latn-ng',
  any: 'any-latn-zz',
  aoj: 'aoj-latn-zz',
  aom: 'aom-latn-zz',
  aoz: 'aoz-latn-id',
  apc: 'apc-arab-zz',
  apd: 'apd-arab-tg',
  ape: 'ape-latn-zz',
  apr: 'apr-latn-zz',
  aps: 'aps-latn-zz',
  apz: 'apz-latn-zz',
  ar: 'ar-arab-eg',
  arc: 'arc-armi-ir',
  'arc-nbat': 'arc-nbat-jo',
  'arc-palm': 'arc-palm-sy',
  arh: 'arh-latn-zz',
  arn: 'arn-latn-cl',
  aro: 'aro-latn-bo',
  arq: 'arq-arab-dz',
  ars: 'ars-arab-sa',
  ary: 'ary-arab-ma',
  arz: 'arz-arab-eg',
  as: 'as-beng-in',
  asa: 'asa-latn-tz',
  ase: 'ase-sgnw-us',
  asg: 'asg-latn-zz',
  aso: 'aso-latn-zz',
  ast: 'ast-latn-es',
  ata: 'ata-latn-zz',
  atg: 'atg-latn-zz',
  atj: 'atj-latn-ca',
  auy: 'auy-latn-zz',
  av: 'av-cyrl-ru',
  avl: 'avl-arab-zz',
  avn: 'avn-latn-zz',
  avt: 'avt-latn-zz',
  avu: 'avu-latn-zz',
  awa: 'awa-deva-in',
  awb: 'awb-latn-zz',
  awo: 'awo-latn-zz',
  awx: 'awx-latn-zz',
  ay: 'ay-latn-bo',
  ayb: 'ayb-latn-zz',
  az: 'az-latn-az',
  'az-arab': 'az-arab-ir',
  'az-iq': 'az-arab-iq',
  'az-ir': 'az-arab-ir',
  'az-ru': 'az-cyrl-ru',
  ba: 'ba-cyrl-ru',
  bal: 'bal-arab-pk',
  ban: 'ban-latn-id',
  bap: 'bap-deva-np',
  bar: 'bar-latn-at',
  bas: 'bas-latn-cm',
  bav: 'bav-latn-zz',
  bax: 'bax-bamu-cm',
  bba: 'bba-latn-zz',
  bbb: 'bbb-latn-zz',
  bbc: 'bbc-latn-id',
  bbd: 'bbd-latn-zz',
  bbj: 'bbj-latn-cm',
  bbp: 'bbp-latn-zz',
  bbr: 'bbr-latn-zz',
  bcf: 'bcf-latn-zz',
  bch: 'bch-latn-zz',
  bci: 'bci-latn-ci',
  bcm: 'bcm-latn-zz',
  bcn: 'bcn-latn-zz',
  bco: 'bco-latn-zz',
  bcq: 'bcq-ethi-zz',
  bcu: 'bcu-latn-zz',
  bdd: 'bdd-latn-zz',
  be: 'be-cyrl-by',
  bef: 'bef-latn-zz',
  beh: 'beh-latn-zz',
  bej: 'bej-arab-sd',
  bem: 'bem-latn-zm',
  bet: 'bet-latn-zz',
  bew: 'bew-latn-id',
  bex: 'bex-latn-zz',
  bez: 'bez-latn-tz',
  bfd: 'bfd-latn-cm',
  bfq: 'bfq-taml-in',
  bft: 'bft-arab-pk',
  bfy: 'bfy-deva-in',
  bg: 'bg-cyrl-bg',
  bgc: 'bgc-deva-in',
  bgn: 'bgn-arab-pk',
  bgx: 'bgx-grek-tr',
  bhb: 'bhb-deva-in',
  bhg: 'bhg-latn-zz',
  bhi: 'bhi-deva-in',
  bhl: 'bhl-latn-zz',
  bho: 'bho-deva-in',
  bhy: 'bhy-latn-zz',
  bi: 'bi-latn-vu',
  bib: 'bib-latn-zz',
  big: 'big-latn-zz',
  bik: 'bik-latn-ph',
  bim: 'bim-latn-zz',
  bin: 'bin-latn-ng',
  bio: 'bio-latn-zz',
  biq: 'biq-latn-zz',
  bjh: 'bjh-latn-zz',
  bji: 'bji-ethi-zz',
  bjj: 'bjj-deva-in',
  bjn: 'bjn-latn-id',
  bjo: 'bjo-latn-zz',
  bjr: 'bjr-latn-zz',
  bjt: 'bjt-latn-sn',
  bjz: 'bjz-latn-zz',
  bkc: 'bkc-latn-zz',
  bkm: 'bkm-latn-cm',
  bkq: 'bkq-latn-zz',
  bku: 'bku-latn-ph',
  bkv: 'bkv-latn-zz',
  bla: 'bla-latn-ca',
  blg: 'blg-latn-my',
  blt: 'blt-tavt-vn',
  bm: 'bm-latn-ml',
  bmh: 'bmh-latn-zz',
  bmk: 'bmk-latn-zz',
  bmq: 'bmq-latn-ml',
  bmu: 'bmu-latn-zz',
  bn: 'bn-beng-bd',
  bng: 'bng-latn-zz',
  bnm: 'bnm-latn-zz',
  bnp: 'bnp-latn-zz',
  bo: 'bo-tibt-cn',
  boj: 'boj-latn-zz',
  bom: 'bom-latn-zz',
  bon: 'bon-latn-zz',
  bpy: 'bpy-beng-in',
  bqc: 'bqc-latn-zz',
  bqi: 'bqi-arab-ir',
  bqp: 'bqp-latn-zz',
  bqv: 'bqv-latn-ci',
  br: 'br-latn-fr',
  bra: 'bra-deva-in',
  brh: 'brh-arab-pk',
  brx: 'brx-deva-in',
  brz: 'brz-latn-zz',
  bs: 'bs-latn-ba',
  bsj: 'bsj-latn-zz',
  bsq: 'bsq-bass-lr',
  bss: 'bss-latn-cm',
  bst: 'bst-ethi-zz',
  bto: 'bto-latn-ph',
  btt: 'btt-latn-zz',
  btv: 'btv-deva-pk',
  bua: 'bua-cyrl-ru',
  buc: 'buc-latn-yt',
  bud: 'bud-latn-zz',
  bug: 'bug-latn-id',
  buk: 'buk-latn-zz',
  bum: 'bum-latn-cm',
  buo: 'buo-latn-zz',
  bus: 'bus-latn-zz',
  buu: 'buu-latn-zz',
  bvb: 'bvb-latn-gq',
  bwd: 'bwd-latn-zz',
  bwr: 'bwr-latn-zz',
  bxh: 'bxh-latn-zz',
  bye: 'bye-latn-zz',
  byn: 'byn-ethi-er',
  byr: 'byr-latn-zz',
  bys: 'bys-latn-zz',
  byv: 'byv-latn-cm',
  byx: 'byx-latn-zz',
  bza: 'bza-latn-zz',
  bze: 'bze-latn-ml',
  bzf: 'bzf-latn-zz',
  bzh: 'bzh-latn-zz',
  bzw: 'bzw-latn-zz',
  ca: 'ca-latn-es',
  cad: 'cad-latn-us',
  can: 'can-latn-zz',
  cbj: 'cbj-latn-zz',
  cch: 'cch-latn-ng',
  ccp: 'ccp-cakm-bd',
  ce: 'ce-cyrl-ru',
  ceb: 'ceb-latn-ph',
  cfa: 'cfa-latn-zz',
  cgg: 'cgg-latn-ug',
  ch: 'ch-latn-gu',
  chk: 'chk-latn-fm',
  chm: 'chm-cyrl-ru',
  cho: 'cho-latn-us',
  chp: 'chp-latn-ca',
  chr: 'chr-cher-us',
  cic: 'cic-latn-us',
  cja: 'cja-arab-kh',
  cjm: 'cjm-cham-vn',
  cjv: 'cjv-latn-zz',
  ckb: 'ckb-arab-iq',
  ckl: 'ckl-latn-zz',
  cko: 'cko-latn-zz',
  cky: 'cky-latn-zz',
  cla: 'cla-latn-zz',
  clc: 'clc-latn-ca',
  cme: 'cme-latn-zz',
  cmg: 'cmg-soyo-mn',
  co: 'co-latn-fr',
  cop: 'cop-copt-eg',
  cps: 'cps-latn-ph',
  cr: 'cr-cans-ca',
  crg: 'crg-latn-ca',
  crh: 'crh-cyrl-ua',
  crk: 'crk-cans-ca',
  crl: 'crl-cans-ca',
  crs: 'crs-latn-sc',
  cs: 'cs-latn-cz',
  csb: 'csb-latn-pl',
  csw: 'csw-cans-ca',
  ctd: 'ctd-pauc-mm',
  cu: 'cu-cyrl-ru',
  'cu-glag': 'cu-glag-bg',
  cv: 'cv-cyrl-ru',
  cy: 'cy-latn-gb',
  da: 'da-latn-dk',
  dad: 'dad-latn-zz',
  daf: 'daf-latn-ci',
  dag: 'dag-latn-zz',
  dah: 'dah-latn-zz',
  dak: 'dak-latn-us',
  dar: 'dar-cyrl-ru',
  dav: 'dav-latn-ke',
  dbd: 'dbd-latn-zz',
  dbq: 'dbq-latn-zz',
  dcc: 'dcc-arab-in',
  ddn: 'ddn-latn-zz',
  de: 'de-latn-de',
  ded: 'ded-latn-zz',
  den: 'den-latn-ca',
  dga: 'dga-latn-zz',
  dgh: 'dgh-latn-zz',
  dgi: 'dgi-latn-zz',
  dgl: 'dgl-arab-zz',
  dgr: 'dgr-latn-ca',
  dgz: 'dgz-latn-zz',
  dia: 'dia-latn-zz',
  dje: 'dje-latn-ne',
  dmf: 'dmf-medf-ng',
  dnj: 'dnj-latn-ci',
  dob: 'dob-latn-zz',
  doi: 'doi-deva-in',
  dop: 'dop-latn-zz',
  dow: 'dow-latn-zz',
  drh: 'drh-mong-cn',
  dri: 'dri-latn-zz',
  drs: 'drs-ethi-zz',
  dsb: 'dsb-latn-de',
  dtm: 'dtm-latn-ml',
  dtp: 'dtp-latn-my',
  dts: 'dts-latn-zz',
  dty: 'dty-deva-np',
  dua: 'dua-latn-cm',
  duc: 'duc-latn-zz',
  dud: 'dud-latn-zz',
  dug: 'dug-latn-zz',
  dv: 'dv-thaa-mv',
  dva: 'dva-latn-zz',
  dww: 'dww-latn-zz',
  dyo: 'dyo-latn-sn',
  dyu: 'dyu-latn-bf',
  dz: 'dz-tibt-bt',
  dzg: 'dzg-latn-zz',
  ebu: 'ebu-latn-ke',
  ee: 'ee-latn-gh',
  efi: 'efi-latn-ng',
  egl: 'egl-latn-it',
  egy: 'egy-egyp-eg',
  eka: 'eka-latn-zz',
  eky: 'eky-kali-mm',
  el: 'el-grek-gr',
  ema: 'ema-latn-zz',
  emi: 'emi-latn-zz',
  en: 'en-latn-us',
  'en-shaw': 'en-shaw-gb',
  enn: 'enn-latn-zz',
  enq: 'enq-latn-zz',
  eo: 'eo-latn-001',
  eri: 'eri-latn-zz',
  es: 'es-latn-es',
  esg: 'esg-gonm-in',
  esu: 'esu-latn-us',
  et: 'et-latn-ee',
  etr: 'etr-latn-zz',
  ett: 'ett-ital-it',
  etu: 'etu-latn-zz',
  etx: 'etx-latn-zz',
  eu: 'eu-latn-es',
  ewo: 'ewo-latn-cm',
  ext: 'ext-latn-es',
  eza: 'eza-latn-zz',
  fa: 'fa-arab-ir',
  faa: 'faa-latn-zz',
  fab: 'fab-latn-zz',
  fag: 'fag-latn-zz',
  fai: 'fai-latn-zz',
  fan: 'fan-latn-gq',
  ff: 'ff-latn-sn',
  'ff-adlm': 'ff-adlm-gn',
  ffi: 'ffi-latn-zz',
  ffm: 'ffm-latn-ml',
  fi: 'fi-latn-fi',
  fia: 'fia-arab-sd',
  fil: 'fil-latn-ph',
  fit: 'fit-latn-se',
  fj: 'fj-latn-fj',
  flr: 'flr-latn-zz',
  fmp: 'fmp-latn-zz',
  fo: 'fo-latn-fo',
  fod: 'fod-latn-zz',
  fon: 'fon-latn-bj',
  for: 'for-latn-zz',
  fpe: 'fpe-latn-zz',
  fqs: 'fqs-latn-zz',
  fr: 'fr-latn-fr',
  frc: 'frc-latn-us',
  frp: 'frp-latn-fr',
  frr: 'frr-latn-de',
  frs: 'frs-latn-de',
  fub: 'fub-arab-cm',
  fud: 'fud-latn-wf',
  fue: 'fue-latn-zz',
  fuf: 'fuf-latn-gn',
  fuh: 'fuh-latn-zz',
  fuq: 'fuq-latn-ne',
  fur: 'fur-latn-it',
  fuv: 'fuv-latn-ng',
  fuy: 'fuy-latn-zz',
  fvr: 'fvr-latn-sd',
  fy: 'fy-latn-nl',
  ga: 'ga-latn-ie',
  gaa: 'gaa-latn-gh',
  gaf: 'gaf-latn-zz',
  gag: 'gag-latn-md',
  gah: 'gah-latn-zz',
  gaj: 'gaj-latn-zz',
  gam: 'gam-latn-zz',
  gan: 'gan-hans-cn',
  gaw: 'gaw-latn-zz',
  gay: 'gay-latn-id',
  gba: 'gba-latn-zz',
  gbf: 'gbf-latn-zz',
  gbm: 'gbm-deva-in',
  gby: 'gby-latn-zz',
  gbz: 'gbz-arab-ir',
  gcr: 'gcr-latn-gf',
  gd: 'gd-latn-gb',
  gde: 'gde-latn-zz',
  gdn: 'gdn-latn-zz',
  gdr: 'gdr-latn-zz',
  geb: 'geb-latn-zz',
  gej: 'gej-latn-zz',
  gel: 'gel-latn-zz',
  gez: 'gez-ethi-et',
  gfk: 'gfk-latn-zz',
  ggn: 'ggn-deva-np',
  ghs: 'ghs-latn-zz',
  gil: 'gil-latn-ki',
  gim: 'gim-latn-zz',
  gjk: 'gjk-arab-pk',
  gjn: 'gjn-latn-zz',
  gju: 'gju-arab-pk',
  gkn: 'gkn-latn-zz',
  gkp: 'gkp-latn-zz',
  gl: 'gl-latn-es',
  glk: 'glk-arab-ir',
  gmm: 'gmm-latn-zz',
  gmv: 'gmv-ethi-zz',
  gn: 'gn-latn-py',
  gnd: 'gnd-latn-zz',
  gng: 'gng-latn-zz',
  god: 'god-latn-zz',
  gof: 'gof-ethi-zz',
  goi: 'goi-latn-zz',
  gom: 'gom-deva-in',
  gon: 'gon-telu-in',
  gor: 'gor-latn-id',
  gos: 'gos-latn-nl',
  got: 'got-goth-ua',
  grb: 'grb-latn-zz',
  grc: 'grc-cprt-cy',
  'grc-linb': 'grc-linb-gr',
  grt: 'grt-beng-in',
  grw: 'grw-latn-zz',
  gsw: 'gsw-latn-ch',
  gu: 'gu-gujr-in',
  gub: 'gub-latn-br',
  guc: 'guc-latn-co',
  gud: 'gud-latn-zz',
  gur: 'gur-latn-gh',
  guw: 'guw-latn-zz',
  gux: 'gux-latn-zz',
  guz: 'guz-latn-ke',
  gv: 'gv-latn-im',
  gvf: 'gvf-latn-zz',
  gvr: 'gvr-deva-np',
  gvs: 'gvs-latn-zz',
  gwc: 'gwc-arab-zz',
  gwi: 'gwi-latn-ca',
  gwt: 'gwt-arab-zz',
  gyi: 'gyi-latn-zz',
  ha: 'ha-latn-ng',
  'ha-cm': 'ha-arab-cm',
  'ha-sd': 'ha-arab-sd',
  hag: 'hag-latn-zz',
  hak: 'hak-hans-cn',
  ham: 'ham-latn-zz',
  haw: 'haw-latn-us',
  haz: 'haz-arab-af',
  hbb: 'hbb-latn-zz',
  hdy: 'hdy-ethi-zz',
  he: 'he-hebr-il',
  hhy: 'hhy-latn-zz',
  hi: 'hi-deva-in',
  'hi-latn': 'hi-latn-in',
  hia: 'hia-latn-zz',
  hif: 'hif-latn-fj',
  hig: 'hig-latn-zz',
  hih: 'hih-latn-zz',
  hil: 'hil-latn-ph',
  hla: 'hla-latn-zz',
  hlu: 'hlu-hluw-tr',
  hmd: 'hmd-plrd-cn',
  hmt: 'hmt-latn-zz',
  hnd: 'hnd-arab-pk',
  hne: 'hne-deva-in',
  hnj: 'hnj-hmnp-us',
  hnn: 'hnn-latn-ph',
  hno: 'hno-arab-pk',
  ho: 'ho-latn-pg',
  hoc: 'hoc-deva-in',
  hoj: 'hoj-deva-in',
  hot: 'hot-latn-zz',
  hr: 'hr-latn-hr',
  hsb: 'hsb-latn-de',
  hsn: 'hsn-hans-cn',
  ht: 'ht-latn-ht',
  hu: 'hu-latn-hu',
  hui: 'hui-latn-zz',
  hur: 'hur-latn-ca',
  hy: 'hy-armn-am',
  hz: 'hz-latn-na',
  ia: 'ia-latn-001',
  ian: 'ian-latn-zz',
  iar: 'iar-latn-zz',
  iba: 'iba-latn-my',
  ibb: 'ibb-latn-ng',
  iby: 'iby-latn-zz',
  ica: 'ica-latn-zz',
  ich: 'ich-latn-zz',
  id: 'id-latn-id',
  idd: 'idd-latn-zz',
  idi: 'idi-latn-zz',
  idu: 'idu-latn-zz',
  ife: 'ife-latn-tg',
  ig: 'ig-latn-ng',
  igb: 'igb-latn-zz',
  ige: 'ige-latn-zz',
  ii: 'ii-yiii-cn',
  ijj: 'ijj-latn-zz',
  ik: 'ik-latn-us',
  ikk: 'ikk-latn-zz',
  ikw: 'ikw-latn-zz',
  ikx: 'ikx-latn-zz',
  ilo: 'ilo-latn-ph',
  imo: 'imo-latn-zz',
  in: 'in-latn-id',
  inh: 'inh-cyrl-ru',
  io: 'io-latn-001',
  iou: 'iou-latn-zz',
  iri: 'iri-latn-zz',
  is: 'is-latn-is',
  it: 'it-latn-it',
  iu: 'iu-cans-ca',
  iw: 'iw-hebr-il',
  iwm: 'iwm-latn-zz',
  iws: 'iws-latn-zz',
  izh: 'izh-latn-ru',
  izi: 'izi-latn-zz',
  ja: 'ja-jpan-jp',
  jab: 'jab-latn-zz',
  jam: 'jam-latn-jm',
  jar: 'jar-latn-zz',
  jbo: 'jbo-latn-001',
  jbu: 'jbu-latn-zz',
  jen: 'jen-latn-zz',
  jgk: 'jgk-latn-zz',
  jgo: 'jgo-latn-cm',
  ji: 'ji-hebr-ua',
  jib: 'jib-latn-zz',
  jmc: 'jmc-latn-tz',
  jml: 'jml-deva-np',
  jra: 'jra-latn-zz',
  jut: 'jut-latn-dk',
  jv: 'jv-latn-id',
  jw: 'jw-latn-id',
  ka: 'ka-geor-ge',
  kaa: 'kaa-cyrl-uz',
  kab: 'kab-latn-dz',
  kac: 'kac-latn-mm',
  kad: 'kad-latn-zz',
  kai: 'kai-latn-zz',
  kaj: 'kaj-latn-ng',
  kam: 'kam-latn-ke',
  kao: 'kao-latn-ml',
  kaw: 'kaw-kawi-id',
  kbd: 'kbd-cyrl-ru',
  kbm: 'kbm-latn-zz',
  kbp: 'kbp-latn-zz',
  kbq: 'kbq-latn-zz',
  kbx: 'kbx-latn-zz',
  kby: 'kby-arab-ne',
  kcg: 'kcg-latn-ng',
  kck: 'kck-latn-zw',
  kcl: 'kcl-latn-zz',
  kct: 'kct-latn-zz',
  kde: 'kde-latn-tz',
  kdh: 'kdh-latn-tg',
  kdl: 'kdl-latn-zz',
  kdt: 'kdt-thai-th',
  kea: 'kea-latn-cv',
  ken: 'ken-latn-cm',
  kez: 'kez-latn-zz',
  kfo: 'kfo-latn-ci',
  kfr: 'kfr-deva-in',
  kfy: 'kfy-deva-in',
  kg: 'kg-latn-cd',
  kge: 'kge-latn-id',
  kgf: 'kgf-latn-zz',
  kgp: 'kgp-latn-br',
  kha: 'kha-latn-in',
  khb: 'khb-talu-cn',
  khn: 'khn-deva-in',
  khq: 'khq-latn-ml',
  khs: 'khs-latn-zz',
  kht: 'kht-mymr-in',
  khw: 'khw-arab-pk',
  khz: 'khz-latn-zz',
  ki: 'ki-latn-ke',
  kij: 'kij-latn-zz',
  kiu: 'kiu-latn-tr',
  kiw: 'kiw-latn-zz',
  kj: 'kj-latn-na',
  kjd: 'kjd-latn-zz',
  kjg: 'kjg-laoo-la',
  kjs: 'kjs-latn-zz',
  kjy: 'kjy-latn-zz',
  kk: 'kk-cyrl-kz',
  'kk-af': 'kk-arab-af',
  'kk-arab': 'kk-arab-cn',
  'kk-cn': 'kk-arab-cn',
  'kk-ir': 'kk-arab-ir',
  'kk-mn': 'kk-arab-mn',
  kkc: 'kkc-latn-zz',
  kkj: 'kkj-latn-cm',
  kl: 'kl-latn-gl',
  kln: 'kln-latn-ke',
  klq: 'klq-latn-zz',
  klt: 'klt-latn-zz',
  klx: 'klx-latn-zz',
  km: 'km-khmr-kh',
  kmb: 'kmb-latn-ao',
  kmh: 'kmh-latn-zz',
  kmo: 'kmo-latn-zz',
  kms: 'kms-latn-zz',
  kmu: 'kmu-latn-zz',
  kmw: 'kmw-latn-zz',
  kn: 'kn-knda-in',
  knf: 'knf-latn-gw',
  knp: 'knp-latn-zz',
  ko: 'ko-kore-kr',
  koi: 'koi-cyrl-ru',
  kok: 'kok-deva-in',
  kol: 'kol-latn-zz',
  kos: 'kos-latn-fm',
  koz: 'koz-latn-zz',
  kpe: 'kpe-latn-lr',
  kpf: 'kpf-latn-zz',
  kpo: 'kpo-latn-zz',
  kpr: 'kpr-latn-zz',
  kpx: 'kpx-latn-zz',
  kqb: 'kqb-latn-zz',
  kqf: 'kqf-latn-zz',
  kqs: 'kqs-latn-zz',
  kqy: 'kqy-ethi-zz',
  kr: 'kr-latn-zz',
  krc: 'krc-cyrl-ru',
  kri: 'kri-latn-sl',
  krj: 'krj-latn-ph',
  krl: 'krl-latn-ru',
  krs: 'krs-latn-zz',
  kru: 'kru-deva-in',
  ks: 'ks-arab-in',
  ksb: 'ksb-latn-tz',
  ksd: 'ksd-latn-zz',
  ksf: 'ksf-latn-cm',
  ksh: 'ksh-latn-de',
  ksj: 'ksj-latn-zz',
  ksr: 'ksr-latn-zz',
  ktb: 'ktb-ethi-zz',
  ktm: 'ktm-latn-zz',
  kto: 'kto-latn-zz',
  ktr: 'ktr-latn-my',
  ku: 'ku-latn-tr',
  'ku-arab': 'ku-arab-iq',
  'ku-lb': 'ku-arab-lb',
  'ku-yezi': 'ku-yezi-ge',
  kub: 'kub-latn-zz',
  kud: 'kud-latn-zz',
  kue: 'kue-latn-zz',
  kuj: 'kuj-latn-zz',
  kum: 'kum-cyrl-ru',
  kun: 'kun-latn-zz',
  kup: 'kup-latn-zz',
  kus: 'kus-latn-zz',
  kv: 'kv-cyrl-ru',
  kvg: 'kvg-latn-zz',
  kvr: 'kvr-latn-id',
  kvx: 'kvx-arab-pk',
  kw: 'kw-latn-gb',
  kwj: 'kwj-latn-zz',
  kwk: 'kwk-latn-ca',
  kwo: 'kwo-latn-zz',
  kwq: 'kwq-latn-zz',
  kxa: 'kxa-latn-zz',
  kxc: 'kxc-ethi-zz',
  kxe: 'kxe-latn-zz',
  kxl: 'kxl-deva-in',
  kxm: 'kxm-thai-th',
  kxp: 'kxp-arab-pk',
  kxw: 'kxw-latn-zz',
  kxz: 'kxz-latn-zz',
  ky: 'ky-cyrl-kg',
  'ky-arab': 'ky-arab-cn',
  'ky-cn': 'ky-arab-cn',
  'ky-latn': 'ky-latn-tr',
  'ky-tr': 'ky-latn-tr',
  kye: 'kye-latn-zz',
  kyx: 'kyx-latn-zz',
  kzh: 'kzh-arab-zz',
  kzj: 'kzj-latn-my',
  kzr: 'kzr-latn-zz',
  kzt: 'kzt-latn-my',
  la: 'la-latn-va',
  lab: 'lab-lina-gr',
  lad: 'lad-hebr-il',
  lag: 'lag-latn-tz',
  lah: 'lah-arab-pk',
  laj: 'laj-latn-ug',
  las: 'las-latn-zz',
  lb: 'lb-latn-lu',
  lbe: 'lbe-cyrl-ru',
  lbu: 'lbu-latn-zz',
  lbw: 'lbw-latn-id',
  lcm: 'lcm-latn-zz',
  lcp: 'lcp-thai-cn',
  ldb: 'ldb-latn-zz',
  led: 'led-latn-zz',
  lee: 'lee-latn-zz',
  lem: 'lem-latn-zz',
  lep: 'lep-lepc-in',
  leq: 'leq-latn-zz',
  leu: 'leu-latn-zz',
  lez: 'lez-cyrl-ru',
  lg: 'lg-latn-ug',
  lgg: 'lgg-latn-zz',
  li: 'li-latn-nl',
  lia: 'lia-latn-zz',
  lid: 'lid-latn-zz',
  lif: 'lif-deva-np',
  'lif-limb': 'lif-limb-in',
  lig: 'lig-latn-zz',
  lih: 'lih-latn-zz',
  lij: 'lij-latn-it',
  lil: 'lil-latn-ca',
  lis: 'lis-lisu-cn',
  ljp: 'ljp-latn-id',
  lki: 'lki-arab-ir',
  lkt: 'lkt-latn-us',
  lle: 'lle-latn-zz',
  lln: 'lln-latn-zz',
  lmn: 'lmn-telu-in',
  lmo: 'lmo-latn-it',
  lmp: 'lmp-latn-zz',
  ln: 'ln-latn-cd',
  lns: 'lns-latn-zz',
  lnu: 'lnu-latn-zz',
  lo: 'lo-laoo-la',
  loj: 'loj-latn-zz',
  lok: 'lok-latn-zz',
  lol: 'lol-latn-cd',
  lor: 'lor-latn-zz',
  los: 'los-latn-zz',
  loz: 'loz-latn-zm',
  lrc: 'lrc-arab-ir',
  lt: 'lt-latn-lt',
  ltg: 'ltg-latn-lv',
  lu: 'lu-latn-cd',
  lua: 'lua-latn-cd',
  luo: 'luo-latn-ke',
  luy: 'luy-latn-ke',
  luz: 'luz-arab-ir',
  lv: 'lv-latn-lv',
  lwl: 'lwl-thai-th',
  lzh: 'lzh-hans-cn',
  lzz: 'lzz-latn-tr',
  mad: 'mad-latn-id',
  maf: 'maf-latn-cm',
  mag: 'mag-deva-in',
  mai: 'mai-deva-in',
  mak: 'mak-latn-id',
  man: 'man-latn-gm',
  'man-gn': 'man-nkoo-gn',
  'man-nkoo': 'man-nkoo-gn',
  mas: 'mas-latn-ke',
  maw: 'maw-latn-zz',
  maz: 'maz-latn-mx',
  mbh: 'mbh-latn-zz',
  mbo: 'mbo-latn-zz',
  mbq: 'mbq-latn-zz',
  mbu: 'mbu-latn-zz',
  mbw: 'mbw-latn-zz',
  mci: 'mci-latn-zz',
  mcp: 'mcp-latn-zz',
  mcq: 'mcq-latn-zz',
  mcr: 'mcr-latn-zz',
  mcu: 'mcu-latn-zz',
  mda: 'mda-latn-zz',
  mde: 'mde-arab-zz',
  mdf: 'mdf-cyrl-ru',
  mdh: 'mdh-latn-ph',
  mdj: 'mdj-latn-zz',
  mdr: 'mdr-latn-id',
  mdx: 'mdx-ethi-zz',
  med: 'med-latn-zz',
  mee: 'mee-latn-zz',
  mek: 'mek-latn-zz',
  men: 'men-latn-sl',
  mer: 'mer-latn-ke',
  met: 'met-latn-zz',
  meu: 'meu-latn-zz',
  mfa: 'mfa-arab-th',
  mfe: 'mfe-latn-mu',
  mfn: 'mfn-latn-zz',
  mfo: 'mfo-latn-zz',
  mfq: 'mfq-latn-zz',
  mg: 'mg-latn-mg',
  mgh: 'mgh-latn-mz',
  mgl: 'mgl-latn-zz',
  mgo: 'mgo-latn-cm',
  mgp: 'mgp-deva-np',
  mgy: 'mgy-latn-tz',
  mh: 'mh-latn-mh',
  mhi: 'mhi-latn-zz',
  mhl: 'mhl-latn-zz',
  mi: 'mi-latn-nz',
  mic: 'mic-latn-ca',
  mif: 'mif-latn-zz',
  min: 'min-latn-id',
  miw: 'miw-latn-zz',
  mk: 'mk-cyrl-mk',
  mki: 'mki-arab-zz',
  mkl: 'mkl-latn-zz',
  mkp: 'mkp-latn-zz',
  mkw: 'mkw-latn-zz',
  ml: 'ml-mlym-in',
  mle: 'mle-latn-zz',
  mlp: 'mlp-latn-zz',
  mls: 'mls-latn-sd',
  mmo: 'mmo-latn-zz',
  mmu: 'mmu-latn-zz',
  mmx: 'mmx-latn-zz',
  mn: 'mn-cyrl-mn',
  'mn-cn': 'mn-mong-cn',
  'mn-mong': 'mn-mong-cn',
  mna: 'mna-latn-zz',
  mnf: 'mnf-latn-zz',
  mni: 'mni-beng-in',
  mnw: 'mnw-mymr-mm',
  mo: 'mo-latn-ro',
  moa: 'moa-latn-zz',
  moe: 'moe-latn-ca',
  moh: 'moh-latn-ca',
  mos: 'mos-latn-bf',
  mox: 'mox-latn-zz',
  mpp: 'mpp-latn-zz',
  mps: 'mps-latn-zz',
  mpt: 'mpt-latn-zz',
  mpx: 'mpx-latn-zz',
  mql: 'mql-latn-zz',
  mr: 'mr-deva-in',
  mrd: 'mrd-deva-np',
  mrj: 'mrj-cyrl-ru',
  mro: 'mro-mroo-bd',
  ms: 'ms-latn-my',
  'ms-cc': 'ms-arab-cc',
  mt: 'mt-latn-mt',
  mtc: 'mtc-latn-zz',
  mtf: 'mtf-latn-zz',
  mti: 'mti-latn-zz',
  mtr: 'mtr-deva-in',
  mua: 'mua-latn-cm',
  mur: 'mur-latn-zz',
  mus: 'mus-latn-us',
  mva: 'mva-latn-zz',
  mvn: 'mvn-latn-zz',
  mvy: 'mvy-arab-pk',
  mwk: 'mwk-latn-ml',
  mwr: 'mwr-deva-in',
  mwv: 'mwv-latn-id',
  mww: 'mww-hmnp-us',
  mxc: 'mxc-latn-zw',
  mxm: 'mxm-latn-zz',
  my: 'my-mymr-mm',
  myk: 'myk-latn-zz',
  mym: 'mym-ethi-zz',
  myv: 'myv-cyrl-ru',
  myw: 'myw-latn-zz',
  myx: 'myx-latn-ug',
  myz: 'myz-mand-ir',
  mzk: 'mzk-latn-zz',
  mzm: 'mzm-latn-zz',
  mzn: 'mzn-arab-ir',
  mzp: 'mzp-latn-zz',
  mzw: 'mzw-latn-zz',
  mzz: 'mzz-latn-zz',
  na: 'na-latn-nr',
  nac: 'nac-latn-zz',
  naf: 'naf-latn-zz',
  nak: 'nak-latn-zz',
  nan: 'nan-hans-cn',
  nap: 'nap-latn-it',
  naq: 'naq-latn-na',
  nas: 'nas-latn-zz',
  nb: 'nb-latn-no',
  nca: 'nca-latn-zz',
  nce: 'nce-latn-zz',
  ncf: 'ncf-latn-zz',
  nch: 'nch-latn-mx',
  nco: 'nco-latn-zz',
  ncu: 'ncu-latn-zz',
  nd: 'nd-latn-zw',
  ndc: 'ndc-latn-mz',
  nds: 'nds-latn-de',
  ne: 'ne-deva-np',
  neb: 'neb-latn-zz',
  new: 'new-deva-np',
  nex: 'nex-latn-zz',
  nfr: 'nfr-latn-zz',
  ng: 'ng-latn-na',
  nga: 'nga-latn-zz',
  ngb: 'ngb-latn-zz',
  ngl: 'ngl-latn-mz',
  nhb: 'nhb-latn-zz',
  nhe: 'nhe-latn-mx',
  nhw: 'nhw-latn-mx',
  nif: 'nif-latn-zz',
  nii: 'nii-latn-zz',
  nij: 'nij-latn-id',
  nin: 'nin-latn-zz',
  niu: 'niu-latn-nu',
  niy: 'niy-latn-zz',
  niz: 'niz-latn-zz',
  njo: 'njo-latn-in',
  nkg: 'nkg-latn-zz',
  nko: 'nko-latn-zz',
  nl: 'nl-latn-nl',
  nmg: 'nmg-latn-cm',
  nmz: 'nmz-latn-zz',
  nn: 'nn-latn-no',
  nnf: 'nnf-latn-zz',
  nnh: 'nnh-latn-cm',
  nnk: 'nnk-latn-zz',
  nnm: 'nnm-latn-zz',
  nnp: 'nnp-wcho-in',
  no: 'no-latn-no',
  nod: 'nod-lana-th',
  noe: 'noe-deva-in',
  non: 'non-runr-se',
  nop: 'nop-latn-zz',
  nou: 'nou-latn-zz',
  nqo: 'nqo-nkoo-gn',
  nr: 'nr-latn-za',
  nrb: 'nrb-latn-zz',
  nsk: 'nsk-cans-ca',
  nsn: 'nsn-latn-zz',
  nso: 'nso-latn-za',
  nss: 'nss-latn-zz',
  nst: 'nst-tnsa-in',
  ntm: 'ntm-latn-zz',
  ntr: 'ntr-latn-zz',
  nui: 'nui-latn-zz',
  nup: 'nup-latn-zz',
  nus: 'nus-latn-ss',
  nuv: 'nuv-latn-zz',
  nux: 'nux-latn-zz',
  nv: 'nv-latn-us',
  nwb: 'nwb-latn-zz',
  nxq: 'nxq-latn-cn',
  nxr: 'nxr-latn-zz',
  ny: 'ny-latn-mw',
  nym: 'nym-latn-tz',
  nyn: 'nyn-latn-ug',
  nzi: 'nzi-latn-gh',
  oc: 'oc-latn-fr',
  'oc-es': 'oc-latn-es',
  ogc: 'ogc-latn-zz',
  oj: 'oj-cans-ca',
  ojs: 'ojs-cans-ca',
  oka: 'oka-latn-ca',
  okr: 'okr-latn-zz',
  okv: 'okv-latn-zz',
  om: 'om-latn-et',
  ong: 'ong-latn-zz',
  onn: 'onn-latn-zz',
  ons: 'ons-latn-zz',
  opm: 'opm-latn-zz',
  or: 'or-orya-in',
  oro: 'oro-latn-zz',
  oru: 'oru-arab-zz',
  os: 'os-cyrl-ge',
  osa: 'osa-osge-us',
  ota: 'ota-arab-zz',
  otk: 'otk-orkh-mn',
  oui: 'oui-ougr-143',
  ozm: 'ozm-latn-zz',
  pa: 'pa-guru-in',
  'pa-arab': 'pa-arab-pk',
  'pa-pk': 'pa-arab-pk',
  pag: 'pag-latn-ph',
  pal: 'pal-phli-ir',
  'pal-phlp': 'pal-phlp-cn',
  pam: 'pam-latn-ph',
  pap: 'pap-latn-aw',
  pau: 'pau-latn-pw',
  pbi: 'pbi-latn-zz',
  pcd: 'pcd-latn-fr',
  pcm: 'pcm-latn-ng',
  pdc: 'pdc-latn-us',
  pdt: 'pdt-latn-ca',
  ped: 'ped-latn-zz',
  peo: 'peo-xpeo-ir',
  pex: 'pex-latn-zz',
  pfl: 'pfl-latn-de',
  phl: 'phl-arab-zz',
  phn: 'phn-phnx-lb',
  pil: 'pil-latn-zz',
  pip: 'pip-latn-zz',
  pis: 'pis-latn-sb',
  pka: 'pka-brah-in',
  pko: 'pko-latn-ke',
  pl: 'pl-latn-pl',
  pla: 'pla-latn-zz',
  pms: 'pms-latn-it',
  png: 'png-latn-zz',
  pnn: 'pnn-latn-zz',
  pnt: 'pnt-grek-gr',
  pon: 'pon-latn-fm',
  ppa: 'ppa-deva-in',
  ppo: 'ppo-latn-zz',
  pqm: 'pqm-latn-ca',
  pra: 'pra-khar-pk',
  prd: 'prd-arab-ir',
  prg: 'prg-latn-001',
  ps: 'ps-arab-af',
  pss: 'pss-latn-zz',
  pt: 'pt-latn-br',
  ptp: 'ptp-latn-zz',
  puu: 'puu-latn-ga',
  pwa: 'pwa-latn-zz',
  qu: 'qu-latn-pe',
  quc: 'quc-latn-gt',
  qug: 'qug-latn-ec',
  rai: 'rai-latn-zz',
  raj: 'raj-deva-in',
  rao: 'rao-latn-zz',
  rcf: 'rcf-latn-re',
  rej: 'rej-latn-id',
  rel: 'rel-latn-zz',
  res: 'res-latn-zz',
  rgn: 'rgn-latn-it',
  rhg: 'rhg-rohg-mm',
  ria: 'ria-latn-in',
  rif: 'rif-tfng-ma',
  'rif-nl': 'rif-latn-nl',
  rjs: 'rjs-deva-np',
  rkt: 'rkt-beng-bd',
  rm: 'rm-latn-ch',
  rmf: 'rmf-latn-fi',
  rmo: 'rmo-latn-ch',
  rmt: 'rmt-arab-ir',
  rmu: 'rmu-latn-se',
  rn: 'rn-latn-bi',
  rna: 'rna-latn-zz',
  rng: 'rng-latn-mz',
  ro: 'ro-latn-ro',
  rob: 'rob-latn-id',
  rof: 'rof-latn-tz',
  roo: 'roo-latn-zz',
  rro: 'rro-latn-zz',
  rtm: 'rtm-latn-fj',
  ru: 'ru-cyrl-ru',
  rue: 'rue-cyrl-ua',
  rug: 'rug-latn-sb',
  rw: 'rw-latn-rw',
  rwk: 'rwk-latn-tz',
  rwo: 'rwo-latn-zz',
  ryu: 'ryu-kana-jp',
  sa: 'sa-deva-in',
  saf: 'saf-latn-gh',
  sah: 'sah-cyrl-ru',
  saq: 'saq-latn-ke',
  sas: 'sas-latn-id',
  sat: 'sat-olck-in',
  sav: 'sav-latn-sn',
  saz: 'saz-saur-in',
  sba: 'sba-latn-zz',
  sbe: 'sbe-latn-zz',
  sbp: 'sbp-latn-tz',
  sc: 'sc-latn-it',
  sck: 'sck-deva-in',
  scl: 'scl-arab-zz',
  scn: 'scn-latn-it',
  sco: 'sco-latn-gb',
  sd: 'sd-arab-pk',
  'sd-deva': 'sd-deva-in',
  'sd-in': 'sd-deva-in',
  'sd-khoj': 'sd-khoj-in',
  'sd-sind': 'sd-sind-in',
  sdc: 'sdc-latn-it',
  sdh: 'sdh-arab-ir',
  se: 'se-latn-no',
  sef: 'sef-latn-ci',
  seh: 'seh-latn-mz',
  sei: 'sei-latn-mx',
  ses: 'ses-latn-ml',
  sg: 'sg-latn-cf',
  sga: 'sga-ogam-ie',
  sgs: 'sgs-latn-lt',
  sgw: 'sgw-ethi-zz',
  sgz: 'sgz-latn-zz',
  shi: 'shi-tfng-ma',
  shk: 'shk-latn-zz',
  shn: 'shn-mymr-mm',
  shu: 'shu-arab-zz',
  si: 'si-sinh-lk',
  sid: 'sid-latn-et',
  sig: 'sig-latn-zz',
  sil: 'sil-latn-zz',
  sim: 'sim-latn-zz',
  sjr: 'sjr-latn-zz',
  sk: 'sk-latn-sk',
  skc: 'skc-latn-zz',
  skr: 'skr-arab-pk',
  sks: 'sks-latn-zz',
  sl: 'sl-latn-si',
  sld: 'sld-latn-zz',
  sli: 'sli-latn-pl',
  sll: 'sll-latn-zz',
  sly: 'sly-latn-id',
  sm: 'sm-latn-ws',
  sma: 'sma-latn-se',
  smd: 'smd-latn-ao',
  smj: 'smj-latn-se',
  smn: 'smn-latn-fi',
  smp: 'smp-samr-il',
  smq: 'smq-latn-zz',
  sms: 'sms-latn-fi',
  sn: 'sn-latn-zw',
  snb: 'snb-latn-my',
  snc: 'snc-latn-zz',
  snk: 'snk-latn-ml',
  snp: 'snp-latn-zz',
  snx: 'snx-latn-zz',
  sny: 'sny-latn-zz',
  so: 'so-latn-so',
  sog: 'sog-sogd-uz',
  sok: 'sok-latn-zz',
  soq: 'soq-latn-zz',
  sou: 'sou-thai-th',
  soy: 'soy-latn-zz',
  spd: 'spd-latn-zz',
  spl: 'spl-latn-zz',
  sps: 'sps-latn-zz',
  sq: 'sq-latn-al',
  sr: 'sr-cyrl-rs',
  'sr-me': 'sr-latn-me',
  'sr-ro': 'sr-latn-ro',
  'sr-ru': 'sr-latn-ru',
  'sr-tr': 'sr-latn-tr',
  srb: 'srb-sora-in',
  srn: 'srn-latn-sr',
  srr: 'srr-latn-sn',
  srx: 'srx-deva-in',
  ss: 'ss-latn-za',
  ssd: 'ssd-latn-zz',
  ssg: 'ssg-latn-zz',
  ssy: 'ssy-latn-er',
  st: 'st-latn-za',
  stk: 'stk-latn-zz',
  stq: 'stq-latn-de',
  su: 'su-latn-id',
  sua: 'sua-latn-zz',
  sue: 'sue-latn-zz',
  suk: 'suk-latn-tz',
  sur: 'sur-latn-zz',
  sus: 'sus-latn-gn',
  sv: 'sv-latn-se',
  sw: 'sw-latn-tz',
  swb: 'swb-arab-yt',
  swc: 'swc-latn-cd',
  swg: 'swg-latn-de',
  swp: 'swp-latn-zz',
  swv: 'swv-deva-in',
  sxn: 'sxn-latn-id',
  sxw: 'sxw-latn-zz',
  syl: 'syl-beng-bd',
  syr: 'syr-syrc-iq',
  szl: 'szl-latn-pl',
  ta: 'ta-taml-in',
  taj: 'taj-deva-np',
  tal: 'tal-latn-zz',
  tan: 'tan-latn-zz',
  taq: 'taq-latn-zz',
  tbc: 'tbc-latn-zz',
  tbd: 'tbd-latn-zz',
  tbf: 'tbf-latn-zz',
  tbg: 'tbg-latn-zz',
  tbo: 'tbo-latn-zz',
  tbw: 'tbw-latn-ph',
  tbz: 'tbz-latn-zz',
  tci: 'tci-latn-zz',
  tcy: 'tcy-knda-in',
  tdd: 'tdd-tale-cn',
  tdg: 'tdg-deva-np',
  tdh: 'tdh-deva-np',
  tdu: 'tdu-latn-my',
  te: 'te-telu-in',
  ted: 'ted-latn-zz',
  tem: 'tem-latn-sl',
  teo: 'teo-latn-ug',
  tet: 'tet-latn-tl',
  tfi: 'tfi-latn-zz',
  tg: 'tg-cyrl-tj',
  'tg-arab': 'tg-arab-pk',
  'tg-pk': 'tg-arab-pk',
  tgc: 'tgc-latn-zz',
  tgo: 'tgo-latn-zz',
  tgu: 'tgu-latn-zz',
  th: 'th-thai-th',
  thl: 'thl-deva-np',
  thq: 'thq-deva-np',
  thr: 'thr-deva-np',
  ti: 'ti-ethi-et',
  tif: 'tif-latn-zz',
  tig: 'tig-ethi-er',
  tik: 'tik-latn-zz',
  tim: 'tim-latn-zz',
  tio: 'tio-latn-zz',
  tiv: 'tiv-latn-ng',
  tk: 'tk-latn-tm',
  tkl: 'tkl-latn-tk',
  tkr: 'tkr-latn-az',
  tkt: 'tkt-deva-np',
  tl: 'tl-latn-ph',
  tlf: 'tlf-latn-zz',
  tlx: 'tlx-latn-zz',
  tly: 'tly-latn-az',
  tmh: 'tmh-latn-ne',
  tmy: 'tmy-latn-zz',
  tn: 'tn-latn-za',
  tnh: 'tnh-latn-zz',
  to: 'to-latn-to',
  tof: 'tof-latn-zz',
  tog: 'tog-latn-mw',
  tok: 'tok-latn-001',
  toq: 'toq-latn-zz',
  tpi: 'tpi-latn-pg',
  tpm: 'tpm-latn-zz',
  tpz: 'tpz-latn-zz',
  tqo: 'tqo-latn-zz',
  tr: 'tr-latn-tr',
  tru: 'tru-latn-tr',
  trv: 'trv-latn-tw',
  trw: 'trw-arab-pk',
  ts: 'ts-latn-za',
  tsd: 'tsd-grek-gr',
  tsf: 'tsf-deva-np',
  tsg: 'tsg-latn-ph',
  tsj: 'tsj-tibt-bt',
  tsw: 'tsw-latn-zz',
  tt: 'tt-cyrl-ru',
  ttd: 'ttd-latn-zz',
  tte: 'tte-latn-zz',
  ttj: 'ttj-latn-ug',
  ttr: 'ttr-latn-zz',
  tts: 'tts-thai-th',
  ttt: 'ttt-latn-az',
  tuh: 'tuh-latn-zz',
  tul: 'tul-latn-zz',
  tum: 'tum-latn-mw',
  tuq: 'tuq-latn-zz',
  tvd: 'tvd-latn-zz',
  tvl: 'tvl-latn-tv',
  tvu: 'tvu-latn-zz',
  twh: 'twh-latn-zz',
  twq: 'twq-latn-ne',
  txg: 'txg-tang-cn',
  txo: 'txo-toto-in',
  ty: 'ty-latn-pf',
  tya: 'tya-latn-zz',
  tyv: 'tyv-cyrl-ru',
  tzm: 'tzm-latn-ma',
  ubu: 'ubu-latn-zz',
  udi: 'udi-aghb-ru',
  udm: 'udm-cyrl-ru',
  ug: 'ug-arab-cn',
  'ug-cyrl': 'ug-cyrl-kz',
  'ug-kz': 'ug-cyrl-kz',
  'ug-mn': 'ug-cyrl-mn',
  uga: 'uga-ugar-sy',
  uk: 'uk-cyrl-ua',
  uli: 'uli-latn-fm',
  umb: 'umb-latn-ao',
  und: 'en-latn-us',
  'und-002': 'en-latn-ng',
  'und-003': 'en-latn-us',
  'und-005': 'pt-latn-br',
  'und-009': 'en-latn-au',
  'und-011': 'en-latn-ng',
  'und-013': 'es-latn-mx',
  'und-014': 'sw-latn-tz',
  'und-015': 'ar-arab-eg',
  'und-017': 'sw-latn-cd',
  'und-018': 'en-latn-za',
  'und-019': 'en-latn-us',
  'und-021': 'en-latn-us',
  'und-029': 'es-latn-cu',
  'und-030': 'zh-hans-cn',
  'und-034': 'hi-deva-in',
  'und-035': 'id-latn-id',
  'und-039': 'it-latn-it',
  'und-053': 'en-latn-au',
  'und-054': 'en-latn-pg',
  'und-057': 'en-latn-gu',
  'und-061': 'sm-latn-ws',
  'und-142': 'zh-hans-cn',
  'und-143': 'uz-latn-uz',
  'und-145': 'ar-arab-sa',
  'und-150': 'ru-cyrl-ru',
  'und-151': 'ru-cyrl-ru',
  'und-154': 'en-latn-gb',
  'und-155': 'de-latn-de',
  'und-202': 'en-latn-ng',
  'und-419': 'es-latn-419',
  'und-ad': 'ca-latn-ad',
  'und-adlm': 'ff-adlm-gn',
  'und-ae': 'ar-arab-ae',
  'und-af': 'fa-arab-af',
  'und-aghb': 'udi-aghb-ru',
  'und-ahom': 'aho-ahom-in',
  'und-al': 'sq-latn-al',
  'und-am': 'hy-armn-am',
  'und-ao': 'pt-latn-ao',
  'und-aq': 'und-latn-aq',
  'und-ar': 'es-latn-ar',
  'und-arab': 'ar-arab-eg',
  'und-arab-cc': 'ms-arab-cc',
  'und-arab-cn': 'ug-arab-cn',
  'und-arab-gb': 'ur-arab-gb',
  'und-arab-id': 'ms-arab-id',
  'und-arab-in': 'ur-arab-in',
  'und-arab-kh': 'cja-arab-kh',
  'und-arab-mm': 'rhg-arab-mm',
  'und-arab-mn': 'kk-arab-mn',
  'und-arab-mu': 'ur-arab-mu',
  'und-arab-ng': 'ha-arab-ng',
  'und-arab-pk': 'ur-arab-pk',
  'und-arab-tg': 'apd-arab-tg',
  'und-arab-th': 'mfa-arab-th',
  'und-arab-tj': 'fa-arab-tj',
  'und-arab-tr': 'az-arab-tr',
  'und-arab-yt': 'swb-arab-yt',
  'und-armi': 'arc-armi-ir',
  'und-armn': 'hy-armn-am',
  'und-as': 'sm-latn-as',
  'und-at': 'de-latn-at',
  'und-avst': 'ae-avst-ir',
  'und-aw': 'nl-latn-aw',
  'und-ax': 'sv-latn-ax',
  'und-az': 'az-latn-az',
  'und-ba': 'bs-latn-ba',
  'und-bali': 'ban-bali-id',
  'und-bamu': 'bax-bamu-cm',
  'und-bass': 'bsq-bass-lr',
  'und-batk': 'bbc-batk-id',
  'und-bd': 'bn-beng-bd',
  'und-be': 'nl-latn-be',
  'und-beng': 'bn-beng-bd',
  'und-bf': 'fr-latn-bf',
  'und-bg': 'bg-cyrl-bg',
  'und-bh': 'ar-arab-bh',
  'und-bhks': 'sa-bhks-in',
  'und-bi': 'rn-latn-bi',
  'und-bj': 'fr-latn-bj',
  'und-bl': 'fr-latn-bl',
  'und-bn': 'ms-latn-bn',
  'und-bo': 'es-latn-bo',
  'und-bopo': 'zh-bopo-tw',
  'und-bq': 'pap-latn-bq',
  'und-br': 'pt-latn-br',
  'und-brah': 'pka-brah-in',
  'und-brai': 'fr-brai-fr',
  'und-bt': 'dz-tibt-bt',
  'und-bugi': 'bug-bugi-id',
  'und-buhd': 'bku-buhd-ph',
  'und-bv': 'und-latn-bv',
  'und-by': 'be-cyrl-by',
  'und-cakm': 'ccp-cakm-bd',
  'und-cans': 'iu-cans-ca',
  'und-cari': 'xcr-cari-tr',
  'und-cd': 'sw-latn-cd',
  'und-cf': 'fr-latn-cf',
  'und-cg': 'fr-latn-cg',
  'und-ch': 'de-latn-ch',
  'und-cham': 'cjm-cham-vn',
  'und-cher': 'chr-cher-us',
  'und-chrs': 'xco-chrs-uz',
  'und-ci': 'fr-latn-ci',
  'und-cl': 'es-latn-cl',
  'und-cm': 'fr-latn-cm',
  'und-cn': 'zh-hans-cn',
  'und-co': 'es-latn-co',
  'und-copt': 'cop-copt-eg',
  'und-cp': 'und-latn-cp',
  'und-cpmn': 'und-cpmn-cy',
  'und-cpmn-cy': 'und-cpmn-cy',
  'und-cprt': 'grc-cprt-cy',
  'und-cr': 'es-latn-cr',
  'und-cu': 'es-latn-cu',
  'und-cv': 'pt-latn-cv',
  'und-cw': 'pap-latn-cw',
  'und-cy': 'el-grek-cy',
  'und-cyrl': 'ru-cyrl-ru',
  'und-cyrl-al': 'mk-cyrl-al',
  'und-cyrl-ba': 'sr-cyrl-ba',
  'und-cyrl-ge': 'ab-cyrl-ge',
  'und-cyrl-gr': 'mk-cyrl-gr',
  'und-cyrl-md': 'uk-cyrl-md',
  'und-cyrl-ro': 'bg-cyrl-ro',
  'und-cyrl-sk': 'uk-cyrl-sk',
  'und-cyrl-tr': 'kbd-cyrl-tr',
  'und-cyrl-xk': 'sr-cyrl-xk',
  'und-cz': 'cs-latn-cz',
  'und-de': 'de-latn-de',
  'und-deva': 'hi-deva-in',
  'und-deva-bt': 'ne-deva-bt',
  'und-deva-fj': 'hif-deva-fj',
  'und-deva-mu': 'bho-deva-mu',
  'und-deva-pk': 'btv-deva-pk',
  'und-diak': 'dv-diak-mv',
  'und-dj': 'aa-latn-dj',
  'und-dk': 'da-latn-dk',
  'und-do': 'es-latn-do',
  'und-dogr': 'doi-dogr-in',
  'und-dupl': 'fr-dupl-fr',
  'und-dz': 'ar-arab-dz',
  'und-ea': 'es-latn-ea',
  'und-ec': 'es-latn-ec',
  'und-ee': 'et-latn-ee',
  'und-eg': 'ar-arab-eg',
  'und-egyp': 'egy-egyp-eg',
  'und-eh': 'ar-arab-eh',
  'und-elba': 'sq-elba-al',
  'und-elym': 'arc-elym-ir',
  'und-er': 'ti-ethi-er',
  'und-es': 'es-latn-es',
  'und-et': 'am-ethi-et',
  'und-ethi': 'am-ethi-et',
  'und-eu': 'en-latn-ie',
  'und-ez': 'de-latn-ez',
  'und-fi': 'fi-latn-fi',
  'und-fo': 'fo-latn-fo',
  'und-fr': 'fr-latn-fr',
  'und-ga': 'fr-latn-ga',
  'und-ge': 'ka-geor-ge',
  'und-geor': 'ka-geor-ge',
  'und-gf': 'fr-latn-gf',
  'und-gh': 'ak-latn-gh',
  'und-gl': 'kl-latn-gl',
  'und-glag': 'cu-glag-bg',
  'und-gn': 'fr-latn-gn',
  'und-gong': 'wsg-gong-in',
  'und-gonm': 'esg-gonm-in',
  'und-goth': 'got-goth-ua',
  'und-gp': 'fr-latn-gp',
  'und-gq': 'es-latn-gq',
  'und-gr': 'el-grek-gr',
  'und-gran': 'sa-gran-in',
  'und-grek': 'el-grek-gr',
  'und-grek-tr': 'bgx-grek-tr',
  'und-gs': 'und-latn-gs',
  'und-gt': 'es-latn-gt',
  'und-gujr': 'gu-gujr-in',
  'und-guru': 'pa-guru-in',
  'und-gw': 'pt-latn-gw',
  'und-hanb': 'zh-hanb-tw',
  'und-hang': 'ko-hang-kr',
  'und-hani': 'zh-hani-cn',
  'und-hano': 'hnn-hano-ph',
  'und-hans': 'zh-hans-cn',
  'und-hant': 'zh-hant-tw',
  'und-hant-ca': 'yue-hant-ca',
  'und-hebr': 'he-hebr-il',
  'und-hebr-se': 'yi-hebr-se',
  'und-hebr-ua': 'yi-hebr-ua',
  'und-hebr-us': 'yi-hebr-us',
  'und-hira': 'ja-hira-jp',
  'und-hk': 'zh-hant-hk',
  'und-hluw': 'hlu-hluw-tr',
  'und-hm': 'und-latn-hm',
  'und-hmng': 'hnj-hmng-la',
  'und-hmnp': 'hnj-hmnp-us',
  'und-hn': 'es-latn-hn',
  'und-hr': 'hr-latn-hr',
  'und-ht': 'ht-latn-ht',
  'und-hu': 'hu-latn-hu',
  'und-hung': 'hu-hung-hu',
  'und-ic': 'es-latn-ic',
  'und-id': 'id-latn-id',
  'und-il': 'he-hebr-il',
  'und-in': 'hi-deva-in',
  'und-iq': 'ar-arab-iq',
  'und-ir': 'fa-arab-ir',
  'und-is': 'is-latn-is',
  'und-it': 'it-latn-it',
  'und-ital': 'ett-ital-it',
  'und-jamo': 'ko-jamo-kr',
  'und-java': 'jv-java-id',
  'und-jo': 'ar-arab-jo',
  'und-jp': 'ja-jpan-jp',
  'und-jpan': 'ja-jpan-jp',
  'und-kali': 'eky-kali-mm',
  'und-kana': 'ja-kana-jp',
  'und-kawi': 'kaw-kawi-id',
  'und-ke': 'sw-latn-ke',
  'und-kg': 'ky-cyrl-kg',
  'und-kh': 'km-khmr-kh',
  'und-khar': 'pra-khar-pk',
  'und-khmr': 'km-khmr-kh',
  'und-khoj': 'sd-khoj-in',
  'und-kits': 'zkt-kits-cn',
  'und-km': 'ar-arab-km',
  'und-knda': 'kn-knda-in',
  'und-kore': 'ko-kore-kr',
  'und-kp': 'ko-kore-kp',
  'und-kr': 'ko-kore-kr',
  'und-kthi': 'bho-kthi-in',
  'und-kw': 'ar-arab-kw',
  'und-kz': 'ru-cyrl-kz',
  'und-la': 'lo-laoo-la',
  'und-lana': 'nod-lana-th',
  'und-laoo': 'lo-laoo-la',
  'und-latn-af': 'tk-latn-af',
  'und-latn-am': 'ku-latn-am',
  'und-latn-cn': 'za-latn-cn',
  'und-latn-cy': 'tr-latn-cy',
  'und-latn-dz': 'fr-latn-dz',
  'und-latn-et': 'en-latn-et',
  'und-latn-ge': 'ku-latn-ge',
  'und-latn-ir': 'tk-latn-ir',
  'und-latn-km': 'fr-latn-km',
  'und-latn-ma': 'fr-latn-ma',
  'und-latn-mk': 'sq-latn-mk',
  'und-latn-mm': 'kac-latn-mm',
  'und-latn-mo': 'pt-latn-mo',
  'und-latn-mr': 'fr-latn-mr',
  'und-latn-ru': 'krl-latn-ru',
  'und-latn-sy': 'fr-latn-sy',
  'und-latn-tn': 'fr-latn-tn',
  'und-latn-tw': 'trv-latn-tw',
  'und-latn-ua': 'pl-latn-ua',
  'und-lb': 'ar-arab-lb',
  'und-lepc': 'lep-lepc-in',
  'und-li': 'de-latn-li',
  'und-limb': 'lif-limb-in',
  'und-lina': 'lab-lina-gr',
  'und-linb': 'grc-linb-gr',
  'und-lisu': 'lis-lisu-cn',
  'und-lk': 'si-sinh-lk',
  'und-ls': 'st-latn-ls',
  'und-lt': 'lt-latn-lt',
  'und-lu': 'fr-latn-lu',
  'und-lv': 'lv-latn-lv',
  'und-ly': 'ar-arab-ly',
  'und-lyci': 'xlc-lyci-tr',
  'und-lydi': 'xld-lydi-tr',
  'und-ma': 'ar-arab-ma',
  'und-mahj': 'hi-mahj-in',
  'und-maka': 'mak-maka-id',
  'und-mand': 'myz-mand-ir',
  'und-mani': 'xmn-mani-cn',
  'und-marc': 'bo-marc-cn',
  'und-mc': 'fr-latn-mc',
  'und-md': 'ro-latn-md',
  'und-me': 'sr-latn-me',
  'und-medf': 'dmf-medf-ng',
  'und-mend': 'men-mend-sl',
  'und-merc': 'xmr-merc-sd',
  'und-mero': 'xmr-mero-sd',
  'und-mf': 'fr-latn-mf',
  'und-mg': 'mg-latn-mg',
  'und-mk': 'mk-cyrl-mk',
  'und-ml': 'bm-latn-ml',
  'und-mlym': 'ml-mlym-in',
  'und-mm': 'my-mymr-mm',
  'und-mn': 'mn-cyrl-mn',
  'und-mo': 'zh-hant-mo',
  'und-modi': 'mr-modi-in',
  'und-mong': 'mn-mong-cn',
  'und-mq': 'fr-latn-mq',
  'und-mr': 'ar-arab-mr',
  'und-mroo': 'mro-mroo-bd',
  'und-mt': 'mt-latn-mt',
  'und-mtei': 'mni-mtei-in',
  'und-mu': 'mfe-latn-mu',
  'und-mult': 'skr-mult-pk',
  'und-mv': 'dv-thaa-mv',
  'und-mx': 'es-latn-mx',
  'und-my': 'ms-latn-my',
  'und-mymr': 'my-mymr-mm',
  'und-mymr-in': 'kht-mymr-in',
  'und-mymr-th': 'mnw-mymr-th',
  'und-mz': 'pt-latn-mz',
  'und-na': 'af-latn-na',
  'und-nagm': 'unr-nagm-in',
  'und-nand': 'sa-nand-in',
  'und-narb': 'xna-narb-sa',
  'und-nbat': 'arc-nbat-jo',
  'und-nc': 'fr-latn-nc',
  'und-ne': 'ha-latn-ne',
  'und-newa': 'new-newa-np',
  'und-ni': 'es-latn-ni',
  'und-nkoo': 'man-nkoo-gn',
  'und-nl': 'nl-latn-nl',
  'und-no': 'nb-latn-no',
  'und-np': 'ne-deva-np',
  'und-nshu': 'zhx-nshu-cn',
  'und-ogam': 'sga-ogam-ie',
  'und-olck': 'sat-olck-in',
  'und-om': 'ar-arab-om',
  'und-orkh': 'otk-orkh-mn',
  'und-orya': 'or-orya-in',
  'und-osge': 'osa-osge-us',
  'und-osma': 'so-osma-so',
  'und-ougr': 'oui-ougr-143',
  'und-pa': 'es-latn-pa',
  'und-palm': 'arc-palm-sy',
  'und-pauc': 'ctd-pauc-mm',
  'und-pe': 'es-latn-pe',
  'und-perm': 'kv-perm-ru',
  'und-pf': 'fr-latn-pf',
  'und-pg': 'tpi-latn-pg',
  'und-ph': 'fil-latn-ph',
  'und-phag': 'lzh-phag-cn',
  'und-phli': 'pal-phli-ir',
  'und-phlp': 'pal-phlp-cn',
  'und-phnx': 'phn-phnx-lb',
  'und-pk': 'ur-arab-pk',
  'und-pl': 'pl-latn-pl',
  'und-plrd': 'hmd-plrd-cn',
  'und-pm': 'fr-latn-pm',
  'und-pr': 'es-latn-pr',
  'und-prti': 'xpr-prti-ir',
  'und-ps': 'ar-arab-ps',
  'und-pt': 'pt-latn-pt',
  'und-pw': 'pau-latn-pw',
  'und-py': 'gn-latn-py',
  'und-qa': 'ar-arab-qa',
  'und-qo': 'en-latn-dg',
  'und-re': 'fr-latn-re',
  'und-rjng': 'rej-rjng-id',
  'und-ro': 'ro-latn-ro',
  'und-rohg': 'rhg-rohg-mm',
  'und-rs': 'sr-cyrl-rs',
  'und-ru': 'ru-cyrl-ru',
  'und-runr': 'non-runr-se',
  'und-rw': 'rw-latn-rw',
  'und-sa': 'ar-arab-sa',
  'und-samr': 'smp-samr-il',
  'und-sarb': 'xsa-sarb-ye',
  'und-saur': 'saz-saur-in',
  'und-sc': 'fr-latn-sc',
  'und-sd': 'ar-arab-sd',
  'und-se': 'sv-latn-se',
  'und-sgnw': 'ase-sgnw-us',
  'und-shaw': 'en-shaw-gb',
  'und-shrd': 'sa-shrd-in',
  'und-si': 'sl-latn-si',
  'und-sidd': 'sa-sidd-in',
  'und-sind': 'sd-sind-in',
  'und-sinh': 'si-sinh-lk',
  'und-sj': 'nb-latn-sj',
  'und-sk': 'sk-latn-sk',
  'und-sm': 'it-latn-sm',
  'und-sn': 'fr-latn-sn',
  'und-so': 'so-latn-so',
  'und-sogd': 'sog-sogd-uz',
  'und-sogo': 'sog-sogo-uz',
  'und-sora': 'srb-sora-in',
  'und-soyo': 'cmg-soyo-mn',
  'und-sr': 'nl-latn-sr',
  'und-st': 'pt-latn-st',
  'und-sund': 'su-sund-id',
  'und-sv': 'es-latn-sv',
  'und-sy': 'ar-arab-sy',
  'und-sylo': 'syl-sylo-bd',
  'und-syrc': 'syr-syrc-iq',
  'und-tagb': 'tbw-tagb-ph',
  'und-takr': 'doi-takr-in',
  'und-tale': 'tdd-tale-cn',
  'und-talu': 'khb-talu-cn',
  'und-taml': 'ta-taml-in',
  'und-tang': 'txg-tang-cn',
  'und-tavt': 'blt-tavt-vn',
  'und-td': 'fr-latn-td',
  'und-telu': 'te-telu-in',
  'und-tf': 'fr-latn-tf',
  'und-tfng': 'zgh-tfng-ma',
  'und-tg': 'fr-latn-tg',
  'und-tglg': 'fil-tglg-ph',
  'und-th': 'th-thai-th',
  'und-thaa': 'dv-thaa-mv',
  'und-thai': 'th-thai-th',
  'und-thai-cn': 'lcp-thai-cn',
  'und-thai-kh': 'kdt-thai-kh',
  'und-thai-la': 'kdt-thai-la',
  'und-tibt': 'bo-tibt-cn',
  'und-tirh': 'mai-tirh-in',
  'und-tj': 'tg-cyrl-tj',
  'und-tk': 'tkl-latn-tk',
  'und-tl': 'pt-latn-tl',
  'und-tm': 'tk-latn-tm',
  'und-tn': 'ar-arab-tn',
  'und-tnsa': 'nst-tnsa-in',
  'und-to': 'to-latn-to',
  'und-toto': 'txo-toto-in',
  'und-tr': 'tr-latn-tr',
  'und-tv': 'tvl-latn-tv',
  'und-tw': 'zh-hant-tw',
  'und-tz': 'sw-latn-tz',
  'und-ua': 'uk-cyrl-ua',
  'und-ug': 'sw-latn-ug',
  'und-ugar': 'uga-ugar-sy',
  'und-uy': 'es-latn-uy',
  'und-uz': 'uz-latn-uz',
  'und-va': 'it-latn-va',
  'und-vaii': 'vai-vaii-lr',
  'und-ve': 'es-latn-ve',
  'und-vith': 'sq-vith-al',
  'und-vn': 'vi-latn-vn',
  'und-vu': 'bi-latn-vu',
  'und-wara': 'hoc-wara-in',
  'und-wcho': 'nnp-wcho-in',
  'und-wf': 'fr-latn-wf',
  'und-ws': 'sm-latn-ws',
  'und-xk': 'sq-latn-xk',
  'und-xpeo': 'peo-xpeo-ir',
  'und-xsux': 'akk-xsux-iq',
  'und-ye': 'ar-arab-ye',
  'und-yezi': 'ku-yezi-ge',
  'und-yiii': 'ii-yiii-cn',
  'und-yt': 'fr-latn-yt',
  'und-zanb': 'cmg-zanb-mn',
  'und-zw': 'sn-latn-zw',
  unr: 'unr-beng-in',
  'unr-deva': 'unr-deva-np',
  'unr-np': 'unr-deva-np',
  unx: 'unx-beng-in',
  uok: 'uok-latn-zz',
  ur: 'ur-arab-pk',
  uri: 'uri-latn-zz',
  urt: 'urt-latn-zz',
  urw: 'urw-latn-zz',
  usa: 'usa-latn-zz',
  uth: 'uth-latn-zz',
  utr: 'utr-latn-zz',
  uvh: 'uvh-latn-zz',
  uvl: 'uvl-latn-zz',
  uz: 'uz-latn-uz',
  'uz-af': 'uz-arab-af',
  'uz-arab': 'uz-arab-af',
  'uz-cn': 'uz-cyrl-cn',
  vag: 'vag-latn-zz',
  vai: 'vai-vaii-lr',
  van: 'van-latn-zz',
  ve: 've-latn-za',
  vec: 'vec-latn-it',
  vep: 'vep-latn-ru',
  vi: 'vi-latn-vn',
  vic: 'vic-latn-sx',
  viv: 'viv-latn-zz',
  vls: 'vls-latn-be',
  vmf: 'vmf-latn-de',
  vmw: 'vmw-latn-mz',
  vo: 'vo-latn-001',
  vot: 'vot-latn-ru',
  vro: 'vro-latn-ee',
  vun: 'vun-latn-tz',
  vut: 'vut-latn-zz',
  wa: 'wa-latn-be',
  wae: 'wae-latn-ch',
  waj: 'waj-latn-zz',
  wal: 'wal-ethi-et',
  wan: 'wan-latn-zz',
  war: 'war-latn-ph',
  wbp: 'wbp-latn-au',
  wbq: 'wbq-telu-in',
  wbr: 'wbr-deva-in',
  wci: 'wci-latn-zz',
  wer: 'wer-latn-zz',
  wgi: 'wgi-latn-zz',
  whg: 'whg-latn-zz',
  wib: 'wib-latn-zz',
  wiu: 'wiu-latn-zz',
  wiv: 'wiv-latn-zz',
  wja: 'wja-latn-zz',
  wji: 'wji-latn-zz',
  wls: 'wls-latn-wf',
  wmo: 'wmo-latn-zz',
  wnc: 'wnc-latn-zz',
  wni: 'wni-arab-km',
  wnu: 'wnu-latn-zz',
  wo: 'wo-latn-sn',
  wob: 'wob-latn-zz',
  wos: 'wos-latn-zz',
  wrs: 'wrs-latn-zz',
  wsg: 'wsg-gong-in',
  wsk: 'wsk-latn-zz',
  wtm: 'wtm-deva-in',
  wuu: 'wuu-hans-cn',
  wuv: 'wuv-latn-zz',
  wwa: 'wwa-latn-zz',
  xav: 'xav-latn-br',
  xbi: 'xbi-latn-zz',
  xco: 'xco-chrs-uz',
  xcr: 'xcr-cari-tr',
  xes: 'xes-latn-zz',
  xh: 'xh-latn-za',
  xla: 'xla-latn-zz',
  xlc: 'xlc-lyci-tr',
  xld: 'xld-lydi-tr',
  xmf: 'xmf-geor-ge',
  xmn: 'xmn-mani-cn',
  xmr: 'xmr-merc-sd',
  xna: 'xna-narb-sa',
  xnr: 'xnr-deva-in',
  xog: 'xog-latn-ug',
  xon: 'xon-latn-zz',
  xpr: 'xpr-prti-ir',
  xrb: 'xrb-latn-zz',
  xsa: 'xsa-sarb-ye',
  xsi: 'xsi-latn-zz',
  xsm: 'xsm-latn-zz',
  xsr: 'xsr-deva-np',
  xwe: 'xwe-latn-zz',
  yam: 'yam-latn-zz',
  yao: 'yao-latn-mz',
  yap: 'yap-latn-fm',
  yas: 'yas-latn-zz',
  yat: 'yat-latn-zz',
  yav: 'yav-latn-cm',
  yay: 'yay-latn-zz',
  yaz: 'yaz-latn-zz',
  yba: 'yba-latn-zz',
  ybb: 'ybb-latn-cm',
  yby: 'yby-latn-zz',
  yer: 'yer-latn-zz',
  ygr: 'ygr-latn-zz',
  ygw: 'ygw-latn-zz',
  yi: 'yi-hebr-001',
  yko: 'yko-latn-zz',
  yle: 'yle-latn-zz',
  ylg: 'ylg-latn-zz',
  yll: 'yll-latn-zz',
  yml: 'yml-latn-zz',
  yo: 'yo-latn-ng',
  yon: 'yon-latn-zz',
  yrb: 'yrb-latn-zz',
  yre: 'yre-latn-zz',
  yrl: 'yrl-latn-br',
  yss: 'yss-latn-zz',
  yua: 'yua-latn-mx',
  yue: 'yue-hant-hk',
  'yue-cn': 'yue-hans-cn',
  'yue-hans': 'yue-hans-cn',
  yuj: 'yuj-latn-zz',
  yut: 'yut-latn-zz',
  yuw: 'yuw-latn-zz',
  za: 'za-latn-cn',
  zag: 'zag-latn-sd',
  zdj: 'zdj-arab-km',
  zea: 'zea-latn-nl',
  zgh: 'zgh-tfng-ma',
  zh: 'zh-hans-cn',
  'zh-au': 'zh-hant-au',
  'zh-bn': 'zh-hant-bn',
  'zh-bopo': 'zh-bopo-tw',
  'zh-gb': 'zh-hant-gb',
  'zh-gf': 'zh-hant-gf',
  'zh-hanb': 'zh-hanb-tw',
  'zh-hant': 'zh-hant-tw',
  'zh-hk': 'zh-hant-hk',
  'zh-id': 'zh-hant-id',
  'zh-mo': 'zh-hant-mo',
  'zh-pa': 'zh-hant-pa',
  'zh-pf': 'zh-hant-pf',
  'zh-ph': 'zh-hant-ph',
  'zh-sr': 'zh-hant-sr',
  'zh-th': 'zh-hant-th',
  'zh-tw': 'zh-hant-tw',
  'zh-us': 'zh-hant-us',
  'zh-vn': 'zh-hant-vn',
  zhx: 'zhx-nshu-cn',
  zia: 'zia-latn-zz',
  zkt: 'zkt-kits-cn',
  zlm: 'zlm-latn-tg',
  zmi: 'zmi-latn-my',
  zne: 'zne-latn-zz',
  zu: 'zu-latn-za',
  zza: 'zza-latn-tr'
}
